@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-style: normal;
    font-weight: 400;
    vertical-align: baseline;
    border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    overflow-y: scroll;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

input,
textarea {
    padding: 0;
    margin: 0;
}

ol,
ul {
    list-style: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

caption,
th {
    text-align: left;
}

a:focus {
    outline: none;
}

.clearfix::after {
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    content: '.';
}

.clearfix {
    min-height: 1px;
}

* html .clearfix {
    height: 1px;
}

img {
    display: block;
    max-width: 100%;
}

button,
textarea,
select {
    font-family: 'Roboto', Hiragino Sans, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

input,
select {
    font-family: 'Roboto', Hiragino Sans, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, sans-serif;
    transition: 0.3s all;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid transparent;
}

input:focus,
select:focus {
    outline: 0;
    border: 1px solid #81cac4;
}

textarea {
    cursor: auto;
    transition: 0.3s all;
    resize: none;
    border: 1px solid transparent;
}

textarea:focus {
    outline: 0;
    border: 1px solid #81cac4;
}

button {
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    appearance: none;
    border: 0;
}

button:disabled {
    cursor: auto;
}

input[type="submit"],
select {
    cursor: pointer;
}

/*------------------------*/

html,
body {
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    font-size: 13px;
    font-weight: 400;
    color: #222;
    background: #fff;
    -webkit-text-size-adjust: 100%;
}

html *,
body * {
    font-family: 'Roboto', Hiragino Sans, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, sans-serif;
}

html {
    position: relative;
    min-height: 100vh;
}

/* box-sizing */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

input::placeholder,
textarea::placeholder {
    color: #ccc;
    font-weight: 500;
}

.pc {
    display: none;
    @media (min-width: 769px) {
        display: block;
    }
}

.sp {
    display: block;
    @media (min-width: 769px) {
        display: none;
    }
}

#root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    overflow: scroll;
}

main {
    padding-top: 60px;
    flex: 1;
    display: flex;
    flex-flow: column;

    & > div {
        flex: 1;
    }

    @media (min-width: 769px) {
        min-width: 1040px;
        padding-top: 70px;
    }
}
